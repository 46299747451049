import React from "react";
import Clients from "./Clients";
import about1 from "../img/about1.jpg";
import about2 from "../img/about2.jpg";
import about3 from "../img/about3.jpg";
import Expertise from "./Expertise";
import Procedure from "./Procedure";

export default function About() {
  return (
    <div className="">
      <div className="about-main-section">
        <div className="overlay"></div>
        <div className="text-white w-100 d-flex flex-column justify-content-center align-items-center h-100">
          <h1 className="text-center px-3">Cyber Legal Advisor: Your Digital Defence</h1>
        </div>
      </div>
      <div className="about-text-section">
        <div className="container my-5">
        <h1 className="my-2 text-center">About the Firm</h1>
            <div className="d-flex align-items-center justify-content-center">
              <div className="line"></div>
            </div>
          <div className="row my-2">
            
            <div className="col-lg-12 p-5 fs-16 fw-500">
              Cyber Legal Advisor is a dynamic, forward-thinking law firm
              specializing in cyber law and intellectual property rights. We are
              your dedicated ally in navigating the complex legal landscape of
              the digital age. With a deep understanding of India’s regulatory
              environment, we provide comprehensive legal support to businesses
              of all sizes, from startups to established enterprises. Our team
              of seasoned cyber law experts offers strategic, innovative, and
              result-oriented solutions to protect your digital assets, mitigate
              risks, and ensure compliance. Beyond traditional legal counsel, we
              offer specialized services including digital forensics, data
              privacy compliance, and cybersecurity audits. Our commitment to
              excellence, coupled with our in-depth knowledge of emerging
              technologies, positions us as a trusted advisor to businesses
              operating in the digital economy. We are more than just lawyers;
              we are your partners in success.
            </div>
          </div>
          <div className="row">
            <div className="col-lg-4 my-2">
              <img src={about1} alt="about 1" className="about-img " />
            </div>
            <div className="col-lg-4 my-2">
              <img src={about3} alt="about 2" className="about-img" />
            </div>
            <div className="col-lg-4 my-2">
              <img src={about2} alt="about 3" className="about-img" />
            </div>
          </div>
          <Expertise/>
          <div className="clients-section text-center my-5">
          <Procedure/>
      </div>
          <div className="clients-section text-center my-5">
            <h1 className="my-2">Our Clients</h1>
            <div className="d-flex align-items-center justify-content-center">
              <div className="line"></div>
            </div>
            <Clients />
          </div>
        </div>
      </div>
    </div>
  );
}
