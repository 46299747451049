import React, { useRef } from "react";
import Slider from "react-slick";
import images from "./images";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

const Clients = () => {
  const sliderRef = useRef(null);

  const settings = {
    infinite: true,
    speed: 3000,
    slidesToShow: 6, // Default number of slides
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 0,
    cssEase: "linear",
    pauseOnHover: false, // We handle the pause manually
    responsive: [
      {
        breakpoint: 1024, // For tablets and larger screens
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 768, // For mobile screens
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 576, // For very small screens
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const handleMouseEnter = () => {
    sliderRef.current.slickPause(); // Pause the carousel
  };

  const handleMouseLeave = () => {
    sliderRef.current.slickPlay(); // Resume the carousel
  };

  return (
    <div className="carousel my-5">
      <Slider {...settings} ref={sliderRef}>
        {images.map((image, idx) => (
          <div
            className="carousel-card"
            key={idx}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}>
            <div className="card-content m-3">
              <img
                src={image.src}
                alt={image.alt}
                className="skills-icon-size"
              />
            </div>
          </div>
        ))}
      </Slider>
    </div>
  );
};

export default Clients;
