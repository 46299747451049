import c1 from "../img/c1.jpg";
import c2 from "../img/c2.jpg";
import c3 from "../img/c3.png";
import c4 from "../img/c4.png";
import c5 from "../img/c5.png";
import c6 from "../img/c6.jpg";
import c7 from "../img/c7.jpg";
import c8 from "../img/c8.png";
import c9 from "../img/c9.png";
import c10 from "../img/c10.png";
import c11 from "../img/c11.jpg";
import c12 from "../img/c12.png";
import c13 from "../img/c13.jpg";
import c14 from "../img/c14.jpg";
import c15 from "../img/c15.jpg";
import c16 from "../img/c16.jpg";
import c17 from "../img/c17.jpg";

const images = [
  {
    src: c1,
    alt: "Client 1 Icon",
  },
  {
    src: c2,
    alt: "Client 2 Icon",
  },
  {
    src: c3,
    alt: "Client 3 Icon",
  },
  {
    src: c4,
    alt: "Client 4 Icon",
  },
  {
    src: c5,
    alt: "Client 5 Icon",
  },
  {
    src: c6,
    alt: "Client 6 Icon",
  },
  {
    src: c7,
    alt: "Client 7 Icon",
  },
  {
    src: c8,
    alt: "Client 8 Icon",
  },
  {
    src: c9,
    alt: "Client 9 Icon",
  },
  {
    src: c10,
    alt: "Client 10 Icon",
  },
  {
    src: c11,
    alt: "Client 11 Icon",
  },
  {
    src: c12,
    alt: "Client 12 Icon",
  },
  {
    src: c13,
    alt: "Client 13 Icon",
  },
  {
    src: c14,
    alt: "Client 14 Icon",
  },
  {
    src: c15,
    alt: "Client 15 Icon",
  },
  {
    src: c16,
    alt: "Client 16 Icon",
  },
  {
    src: c17,
    alt: "Client 17 Icon",
  },
];

export default images;
