import { useEffect, useState } from "react";
import { caseStudies } from "../data/CaseStudiesData";
import { Link } from "react-router-dom";

export default function CaseStudyList() {
  const [currentCaseStudies, setCurrentCaseStudies] = useState([]);
  const [page, setPage] = useState(1);
  const [loading, setLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);

  const loadCaseStudies = async (newPage) => {
    setLoading(true);
    setTimeout(() => {
      const startIndex = (newPage - 1) * 3;
      const endIndex = newPage * 3;
      const newCases = caseStudies.slice(startIndex, endIndex);

      if (newCases.length === 0) {
        setHasMore(false);
      } else {
        setCurrentCaseStudies((prev) => {
          const existingIds = new Set(prev.map((cs) => cs.id));
          const filteredNewCases = newCases.filter(
            (cs) => !existingIds.has(cs.id)
          );
          return [...prev, ...filteredNewCases];
        });
      }

      setLoading(false);
    }, 1000);
  };
  useEffect(() => {
    loadCaseStudies(page);
  }, [page]);
  useEffect(() => {
    if (hasMore) {
      loadCaseStudies(page);
    }
  }, [page, hasMore]);
  useEffect(() => {
    const handleScroll = () => {
      if (
        window.innerHeight + document.documentElement.scrollTop >=
          document.documentElement.offsetHeight - 1000 &&
        !loading &&
        hasMore
      ) {
        setPage((prevPage) => prevPage + 1);
      }
    };

    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, [loading, hasMore]);

  return (
    <div>
      <div className="case-study-main-section">
        <div className="overlay"></div>
        <div className="text-white w-100 d-flex flex-column justify-content-center align-items-center h-100">
          <h1>Case Studies</h1>
        </div>
      </div>

      <div className="who-we-are-section my-5 text-center">
        <div className="who-we-are-section-content">
          <div className="container">
            <div className="row my-4">
              {currentCaseStudies.map((caseStudy) => (
                <div
                  key={caseStudy.id}
                  className="col-lg-4 col-md-6 my-2"
                  style={{ padding: "1rem" }}>
                  <div className="who-we-are-card card position-relative">
                    <span className="badge badge-primary position-absolute top-0 end-0 m-2">
                      {caseStudy.case_client}
                    </span>
                    <div className="card-body p-4 mt-4">
                      <h4 className="card-title mb-3 fw-bold cursor-pointer">
                        <Link to={`/case-study/${caseStudy.id}`}>
                          {caseStudy.case_title}
                        </Link>
                      </h4>
                      <p className="card-text my-2">
                        {caseStudy.case_content.slice(0, 100)}...
                      </p>
                    </div>
                  </div>
                </div>
              ))}
            </div>
            {loading && hasMore && (
              <div className="spinner-container">
                <div className="spinner"></div>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
