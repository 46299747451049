import React from "react";

export default function Expertise() {
  return (
    <div className="who-we-are-section my-5 text-center">
      <h1 className="my-2">Our Expertise</h1>
      <div className="d-flex align-items-center justify-content-center">
        <div className="line"></div>
      </div>

      <div className="who-we-are-section-content">
        <div className="container">
          <div className="row my-4 ">
            <div className="col-lg-4 my-2">
              <div className="who-we-are-card card">
                <div className="card-body p-4">
                  <h4 className="card-title mb-3 fw-bold">
                    Deep Understanding of India's Regulatory Environment
                  </h4>
                  <p className="card- my-2">
                    We stay abreast of the latest laws and regulations governing
                    cybercrime, data privacy, and intellectual property rights
                    in India.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 my-2">
              <div className="who-we-are-card card">
                <div className="card-body p-4">
                  <h4 className="card-title mb-3 fw-bold">
                    Specialized Services
                  </h4>
                  <p className="card- my-2">
                    Beyond traditional legal counsel, we offer digital
                    forensics, data privacy compliance audits, and cybersecurity
                    assessments.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 my-2">
              <div className="who-we-are-card card">
                <div className="card-body p-4">
                  <h4 className="card-title mb-3 fw-bold">
                    Results-Oriented Approach{" "}
                  </h4>
                  <p className="card- my-2">
                    Our team of experienced cyber law experts provides
                    strategic, innovative, and effective solutions tailored to
                    your specific needs.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
