export const caseStudies = [
  {
    id: 2,
    case_title:
      "Explained: The NSE scam, the 'faceless yogi' and trips to tax havens",
    case_content:
      "Chitra Ramkrishna, a former chairperson of India’s largest bourse – the National Stock Exchange – was manipulated by a “Siddha Purusha/Yogi (an unidentified spiritual guru) and allegedly provided him confidential company information including the NSE’s five year projections, financial data, dividend ratio, business plans, agenda of board meetings and also consulted him on employee annual appraisals, an order by market regulator.",
    case_client: "NSE",
  },
  {
    id: 3,
    case_title: "Hidden Investigations Key to Unravel NSE Algo Scam",
    case_content:
      "Two crucial investigations that could have significant implications on the algo scam or co-location (Colo) scam at the National Stock Exchange (NSE) that are pending conclusion and action are the key to estimating the size of the scam and profiteering from it. The first is about brokers being able to bypass the trade access point (TAP) and put in larger orders and profits and making illegal gain. The second is an expanded study by the Indian School of Business, Hyderabad (ISB, Hyderabad) to quantify the Colo scam which has been variously estimated at as much as Rs50,000 crore.",
    case_client: "Algo",
  },
  {
    id: 4,
    case_title:
      "Ascena Retail Group: A Case For Community And Finding Strength In Numbers",
    case_content:
      "Ascena Retail Group, Inc. (Ascena) is one of the largest women’s specialty retailers in the world. Some of its brands include Ann Taylor, LOFT, Lou & Grey, dressbarn, Lane Bryant, Catherines, and Justice. With around 58,000 associates who work in stores, home offices, distribution centers, and international locations, keeping a company of this stature secure is no easy feat. Enter Mark Tomallo. Tomallo is the Chief Information Security Officer (CISO) for Ascena and his teams are responsible for all cyber, compliance, information security, governance, and risk management functions. 'One of the great things about this organization is there’s a lot of support for cyber security and the protection of data, throughout the organization. And I would say, starting from the board to the audit committee to the executive management to the lines of business — all are incredibly interested in protecting our customer data,' says Tomallo.",
    case_client: "Ascena",
  },
  {
    id: 5,
    case_title: "Is Cyber Security Maturing?",
    case_content:
      "Part of an organization’s mission is to protect data. Whatever the industry regulatory oversight – PCI, FDA, HIPAA, etc. – compliance naturally comes with it, though this hasn’t always been the case. 'Ten to fifteen years ago, the focus was making sure that data was backed up, general controls were in place, and the company was not getting sued,' says Tomallo. Companies in industries without a regulatory reporting process chose not to invest beyond 'reasonable security controls and processes'. The lack of accountability came back to haunt them over time as security incidents and breaches occurred, causing a resurgence in the security field.",
    case_client: "Anubha",
  },
  {
    id: 6,
    case_title:
      "The Need For Security Risk Compliance With Emerging Technologies",
    case_content:
      "A good thing about the security, risk, and compliance industry is that the technologies are standardized. From endpoint protection to monitoring and transport visibility, there’s generally a correlation and aspect logging capability. For fraud detection, you can go all the way back into the data center and walk through scenarios.</p><>Newer technologies worth investigating further include the Internet of Things (IoT) and artificial intelligence (AI). 'And so, like most companies, we are investing in and deploying Big Data-type solutions to understand the baseline activity within the organization in advance of automating with AI.",
    case_client: "GoAir",
  },
  {
    id: 7,
    case_title: "IoT Device Deployments Are Outpacing IoT Security Measures",
    case_content:
      "Certain industries have been slow to embrace AI, including healthcare, retail, and manufacturing. 'AI has been a buzz word for a few years, though companies in these sectors are not yet making the investments,' notes Tomallo who also did a stint at a venture capital firm. 'Numerous companies within healthcare, retail, and manufacturing are engaged with the supplier and vendor community to experiment with machine learning components but it’s the vendors and suppliers rather than the enterprises, which are leading the innovation today. What does machine learning and deep learning look like in our industry?' asks Tomallo. 'Only time will tell.'",
    case_client: "Admin",
  },
  {
    id: 9,
    case_title: "AI Could Escalate New Type Of Voice Phishing Cyber Attacks",
    case_content:
      "While many cyber security professionals have been looking at (and even investing in) the potential benefits of utilizing artificial intelligence (AI) technology within many different business functions, earlier this week, the Israel National Cyber Directorate (INCD) issued a warning of a new type of cyber-attack that leverages AI to impersonate senior enterprise executives. The method instructs company employees to perform transactions including money transfers and other malicious activity on the network. There are recent reports of this type of cyber-attack received at the operational center of the INCD. While business email compromise (BEC) types of fraud oftentimes use social engineering methods for a more effective attack, this new method escalates the attack type by using AI-based software, which makes voice phishing calls to senior executives. The attacking software learns to mimic the voice of a person defined for it and makes a conversation with an employee on behalf of the CEO. It was also reported that today there are programs that, after listening to 20 minutes to a particular voice, can speak everything that the user types in that learned voice.",
    case_client: "Admin",
  },
];
