import React, { useEffect } from "react";
import { useLocation } from "react-router-dom";

export default function Services({ services }) {
  const location = useLocation();
  const queryParams = new URLSearchParams(location.search);
  const serviceId = queryParams.get("service");
  const subServiceId = queryParams.get("subservice");

  useEffect(() => {
    if (serviceId) {
      // Scroll to the service section
      const serviceElement = document.getElementById(serviceId);
      if (serviceElement) {
        serviceElement.scrollIntoView({ behavior: "smooth", block: "center" });

        // Use Bootstrap's Collapse component to open the service
        const bsCollapse = new window.bootstrap.Collapse(serviceElement, {
          toggle: false,
        });
        bsCollapse.show();

        // Optionally, scroll to the specific subservice if needed
        if (subServiceId) {
          const subServiceElement = document.getElementById(subServiceId);
          if (subServiceElement) {
            subServiceElement.scrollIntoView({ behavior: "smooth" });
          }
        }
      }
    }
  }, [serviceId, subServiceId]);

  return (
    <div>
      <div className="service-main-section">
        <div className="overlay"></div>
        <div className="text-white w-100 d-flex flex-column justify-content-center align-items-center h-100">
          <h1>Services</h1>
        </div>
      </div>
      <div className="container my-5">
        <h1 className="my-2 text-center">Your Fintech Partner</h1>
        <div className="d-flex align-items-center justify-content-center">
          <div className="line"></div>
        </div>
        <div className="container mt-5">
          <div className="row my-4 mx-1">
            <div className="col-lg-12 card card-body">
              <div className="d-inline-flex gap-1 text-start">
                <a
                  data-bs-toggle="collapse"
                  href="#fintechOverview"
                  role="button"
                  aria-expanded="false"
                  aria-controls="fintechOverview"
                  className="text-primary">
                  <p>
                    At Cyber Legal Advisory, we're passionate about harnessing
                    the power of technology to revolutionize the financial
                    industry. As a leading fintech solutions provider, we offer
                    innovative tools and services designed to streamline
                    operations, enhance customer experiences, and drive growth.
                    <span>
                      <strong className="fw-bold text-black">
                        <i className="fa-solid fa-caret-down mx-2"></i>
                      </strong>
                      Read More
                    </span>
                  </p>
                </a>
              </div>
              <div
                className="collapse smooth-collapse mt-2"
                id="fintechOverview">
                <div>
                  <p>
                    <strong>Why Choose Fintech?</strong>
                  </p>
                  <ul className="padding-l">
                    <li>
                      <strong>Efficiency and Automation:</strong> Our fintech
                      solutions automate manual processes, streamline workflows,
                      and optimize efficiency, allowing businesses to focus on
                      strategic initiatives.
                    </li>
                    <li>
                      <strong>Enhanced Customer Experience:</strong> Leveraging
                      data analytics, AI, and ML, we help businesses gain
                      valuable insights into customer behavior, preferences, and
                      needs, enabling personalized experiences that drive
                      satisfaction and loyalty.
                    </li>
                    <li>
                      <strong>Scalability and Flexibility:</strong> Our fintech
                      solutions are scalable and flexible, allowing businesses
                      to adapt to changing market dynamics, regulatory
                      requirements, and customer demands.
                    </li>
                    <li>
                      <strong>Cost Savings:</strong> By digitizing, optimizing,
                      and automating processes, our fintech solutions help
                      businesses reduce operating costs, eliminate
                      inefficiencies, and improve resource utilization.
                    </li>
                  </ul>
                  <p>
                    <strong>Our Fintech Solutions</strong>
                  </p>
                  <ul className="padding-l">
                    <li>
                      <strong>Payment Processing:</strong> We offer secure and
                      reliable payment processing solutions that support a wide
                      range of payment methods, including credit/debit cards,
                      mobile wallets, and cryptocurrencies.
                    </li>
                    <li>
                      <strong>Digital Banking:</strong> Our digital banking
                      solutions empower banks and financial institutions to
                      offer a seamless and intuitive digital banking experience
                      to their customers.
                    </li>
                    <li>
                      <strong>Lending Platforms:</strong> We provide lending
                      platforms that streamline the loan origination process,
                      enabling lenders to make faster decisions, reduce risk,
                      and offer competitive loan products.
                    </li>
                    <li>
                      <strong>Wealth Management:</strong> Our wealth management
                      solutions leverage AI and ML algorithms to provide
                      personalized investment advice, portfolio management, and
                      financial planning services.
                    </li>
                    <li>
                      <strong>RegTech and Compliance:</strong> We offer RegTech
                      solutions that help businesses navigate complex regulatory
                      landscapes, automate compliance processes, and mitigate
                      regulatory risks.
                    </li>
                  </ul>
                  <p>
                    <strong>Get Started with Fintech</strong>
                  </p>
                  <p>
                    Ready to unlock the full potential of fintech for your
                    business? Contact us today to learn more about our fintech
                    solutions and how we can help you innovate, optimize, and
                    succeed in the digital economy.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container my-5">
        <h1 className="my-2 text-center">
          Your Partner for Effective Complaint Management
        </h1>
        <div className="d-flex align-items-center justify-content-center">
          <div className="line"></div>
        </div>
        <div className="container mt-5">
          <div className="row my-4 mx-1">
            <div className="col-lg-12 card card-body">
              <div className="d-inline-flex gap-1 text-start">
                <a
                  data-bs-toggle="collapse"
                  href="#complaintManagementOverview"
                  role="button"
                  aria-expanded="false"
                  aria-controls="complaintManagementOverview"
                  className="text-primary">
                  <p>
                    At Cyber Legal Advisory, we understand the critical role of
                    effective complaint management in maintaining transparency,
                    accountability, and public trust for government authorities.
                    Our comprehensive solutions are designed to streamline
                    complaint handling, improve responsiveness, and enhance
                    citizen satisfaction.
                    <span>
                      <strong className="fw-bold text-black">
                        <i className="fa-solid fa-caret-down mx-2"></i>
                      </strong>
                      Read More
                    </span>
                  </p>
                </a>
              </div>
              <div
                className="collapse smooth-collapse mt-2"
                id="complaintManagementOverview">
                <div>
                  <p>
                    <strong>Why Choose Us?</strong>
                  </p>
                  <ul className="padding-l">
                    <li>
                      <strong>Expertise:</strong> With years of experience in
                      complaint management, our team understands the unique
                      challenges faced by government agencies. We develop
                      tailored solutions to meet their specific needs.
                    </li>
                    <li>
                      <strong>Efficiency:</strong> Our solutions optimize
                      workflows, automate processes, and eliminate manual tasks,
                      enabling government authorities to handle complaints more
                      efficiently and effectively.
                    </li>
                    <li>
                      <strong>Transparency:</strong> We prioritize transparency,
                      providing government authorities with real-time visibility
                      into the status and progress of complaints. Our intuitive
                      dashboards and reporting tools enable agencies to track
                      key metrics, analyze trends, and make data-driven
                      decisions.
                    </li>
                    <li>
                      <strong>Citizen Engagement:</strong> Our solutions are
                      focused on improving citizen engagement and satisfaction
                      by providing multiple channels for lodging complaints. We
                      prioritize accessibility, responsiveness, and
                      user-friendliness to ensure that citizens can easily voice
                      their concerns and receive timely resolutions.
                    </li>
                  </ul>
                  <p>
                    <strong>Our Complaint Management Solutions</strong>
                  </p>
                  <ul className="padding-l">
                    <li>
                      <strong>Multichannel Intake:</strong> Our multichannel
                      intake capabilities allow citizens to submit complaints
                      through various channels, including online portals, email,
                      phone, and in-person visits.
                    </li>
                    <li>
                      <strong>Workflow Automation:</strong> Our platform
                      automates routine tasks such as case assignment,
                      prioritization, and escalation, streamlining the complaint
                      resolution process and reducing manual intervention.
                    </li>
                    <li>
                      <strong>Case Management:</strong> Our robust case
                      management system enables government authorities to track
                      the progress of complaints from initiation to resolution.
                    </li>
                    <li>
                      <strong>Analytics and Reporting:</strong> We provide
                      advanced analytics and reporting capabilities that enable
                      government authorities to gain insights into complaint
                      trends, patterns, and root causes.
                    </li>
                  </ul>
                  <p>
                    <strong>Get Started with Complaint Management</strong>
                  </p>
                  <p>
                    Ready to enhance your government agency's complaint
                    management processes?Contact us today to schedule a
                    consultation and learn how we can help you optimize
                    workflows, improve responsiveness, and build trust with
                    citizens through effective complaint management solutions.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container my-5">
        <h1 className="my-2 text-center">Unfreezing Your Bank Account</h1>
        <div className="d-flex align-items-center justify-content-center">
          <div className="line"></div>
        </div>
        <div className="container mt-5">
          <div className="row my-4 mx-1">
            <div className="col-lg-12 card card-body">
              <div className="d-inline-flex gap-1 text-start">
                <a
                  data-bs-toggle="collapse"
                  href="#bankAccountOverview"
                  role="button"
                  aria-expanded="false"
                  aria-controls="bankAccountOverview"
                  className="text-primary">
                  <p>
                    At Cyber Legal Advisory, we understand the frustration and
                    stress that can arise when your bank account is frozen. Our
                    team is dedicated to helping individuals and businesses
                    navigate the complex process of unfreezing bank accounts.
                    <span>
                      <strong className="fw-bold text-black">
                        <i className="fa-solid fa-caret-down mx-2"></i>
                      </strong>
                      Read More
                    </span>
                  </p>
                </a>
              </div>
              <div
                className="collapse smooth-collapse mt-2"
                id="bankAccountOverview">
                <div>
                  <span>
                    <strong>Why Choose Us?</strong>
                  </span>
                  <ul className="padding-l">
                    <li>
                      <strong>Expertise:</strong> With years of experience in
                      the financial industry, we have a deep understanding of
                      regulatory compliance and legal processes.
                    </li>
                    <li>
                      <strong>Dedication:</strong> We are committed to providing
                      personalized and attentive service to each client.
                    </li>
                    <li>
                      <strong>Confidentiality:</strong> We prioritize
                      confidentiality in all our interactions.
                    </li>
                  </ul>
                  <p>
                    <strong>Our Unfreeze Bank Account Services</strong>
                  </p>
                  <ul className="padding-l">
                    <li>
                      <strong>Legal Assistance:</strong> Our experienced legal
                      professionals specialize in representing clients in
                      matters related to frozen bank accounts.
                    </li>
                    <li>
                      <strong>Negotiation with Authorities:</strong> We liaise
                      with law enforcement agencies, banks, and payment service
                      providers to negotiate the unfreezing of your bank
                      account.
                    </li>
                    <li>
                      <strong>Documentation Support:</strong> We assist you in
                      gathering and preparing the necessary documentation to
                      support your case.
                    </li>
                    <li>
                      <strong>Litigation Support:</strong> In cases where
                      negotiations are unsuccessful, we provide litigation
                      support to pursue legal remedies.
                    </li>
                  </ul>
                  <p>
                    <strong>Get Help Today</strong>
                  </p>
                  <p>
                    Don't let a frozen bank account disrupt your financial
                    stability. Contact us today to schedule a consultation and
                    learn how we can help you navigate the unfreezing process
                    and regain access to your funds.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container my-5">
        <h1 className="my-2 text-center">
          Your Transaction Monitoring Partner
        </h1>
        <div className="d-flex align-items-center justify-content-center">
          <div className="line"></div>
        </div>
        <div className="container mt-5">
          <div className="row my-4 mx-1">
            <div className="col-lg-12 card card-body">
              <div className="d-inline-flex gap-1 text-start">
                <a
                  data-bs-toggle="collapse"
                  href="#transactionMonitoringOverview"
                  role="button"
                  aria-expanded="false"
                  aria-controls="transactionMonitoringOverview"
                  className="text-primary">
                  <p>
                    We recognize the critical importance of transaction
                    monitoring for fintech and e-commerce clients in today's
                    dynamic and evolving landscape. Our advanced transaction
                    monitoring solutions are designed to help businesses detect
                    and prevent fraudulent activities, ensure regulatory
                    compliance, and safeguard against financial risks.
                    <span>
                      <strong className="fw-bold text-black">
                        <i className="fa-solid fa-caret-down mx-2"></i>
                      </strong>
                      Read More
                    </span>
                  </p>
                </a>
              </div>
              <div
                className="collapse smooth-collapse mt-2"
                id="transactionMonitoringOverview">
                <div>
                  <p>
                    <strong>Why Transaction Monitoring?</strong>
                  </p>
                  <ul className="padding-l">
                    <li>
                      <strong>Fraud Detection and Prevention:</strong> Our
                      transaction monitoring services leverage advanced
                      algorithms and machine learning techniques to analyze
                      transactional data in real-time, flagging suspicious
                      activities and anomalies for further investigation.
                    </li>
                    <li>
                      <strong>Regulatory Compliance:</strong> Our solutions
                      enable businesses to monitor transactions for compliance
                      with anti-money laundering (AML), know your customer
                      (KYC), and other regulatory requirements.
                    </li>
                    <li>
                      <strong>Risk Management:</strong> Effective risk
                      management is essential for the long-term success of
                      fintech and e-commerce businesses. Our transaction
                      monitoring services provide valuable insights into
                      transactional risks, enabling proactive risk mitigation
                      strategies.
                    </li>
                  </ul>
                  <p>
                    <strong>Our Transaction Monitoring Solutions</strong>
                  </p>
                  <ul className="padding-l">
                    <li>
                      <strong>Real-time Monitoring:</strong> We offer real-time
                      transaction monitoring capabilities to detect suspicious
                      activities and potential risks in real-time.
                    </li>
                    <li>
                      <strong>Alert Management:</strong> Our transaction
                      monitoring platform includes a comprehensive alert
                      management system to prioritize and categorize alerts
                      based on severity and risk level.
                    </li>
                    <li>
                      <strong>Customization and Scalability:</strong> Our
                      solutions are fully customizable and scalable to
                      accommodate the specific needs of our clients.
                    </li>
                    <li>
                      <strong>Comprehensive Reporting:</strong> Our platform
                      generates comprehensive reports and analytics to provide
                      businesses with valuable insights into their transactional
                      activities.
                    </li>
                  </ul>
                  <p>
                    <strong>Get Started with Transaction Monitoring</strong>
                  </p>
                  <p>
                    Ready to enhance your fraud detection, regulatory
                    compliance, and risk management capabilities? Contact us
                    today to learn more about our transaction monitoring
                    services and how we can help you protect your business and
                    your customers from financial threats and regulatory risks.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container my-5">
        <h1 className="my-2 text-center">Your Lien Removal Expert</h1>
        <div className="d-flex align-items-center justify-content-center">
          <div className="line"></div>
        </div>
        <div className="container mt-5">
          <div className="row my-4 mx-1">
            <div className="col-lg-12 card card-body">
              <div className="d-inline-flex gap-1 text-start">
                <a
                  data-bs-toggle="collapse"
                  href="#lienRemovalOverview"
                  role="button"
                  aria-expanded="false"
                  aria-controls="lienRemovalOverview"
                  className="text-primary">
                  <p>
                    At Cyber Legal Advisory, we understand the stress and
                    uncertainty that can come with having a lien placed on your
                    property. Our team is dedicated to helping you navigate the
                    lien removal process with ease and confidence.
                    <span>
                      <strong className="fw-bold text-black">
                        <i className="fa-solid fa-caret-down mx-2"></i>
                      </strong>
                      Read More
                    </span>
                  </p>
                </a>
              </div>
              <div
                className="collapse smooth-collapse mt-2"
                id="lienRemovalOverview">
                <div>
                  <p>
                    <strong>Why Choose Us?</strong>
                  </p>
                  <ul className="padding-l">
                    <li>
                      <strong>Expertise:</strong> With years of experience in
                      lien removal, our team has the knowledge and expertise to
                      guide you through the process.
                    </li>
                    <li>
                      <strong>Dedication:</strong> We are committed to providing
                      personalized and attentive service to each client.
                    </li>
                    <li>
                      <strong>Results:</strong> Our track record speaks for
                      itself. We have helped numerous clients successfully
                      remove liens from their properties.
                    </li>
                  </ul>
                  <p>
                    <strong>Our Lien Removal Assistance Services</strong>
                  </p>
                  <ul className="padding-l">
                    <li>
                      <strong>Lien Assessment:</strong> We conduct a
                      comprehensive assessment of your lien situation to
                      understand the nature of the lien and develop a strategy
                      to challenge its validity or negotiate its removal.
                    </li>
                    <li>
                      <strong>Negotiation and Settlement:</strong> In many
                      cases, liens can be resolved through negotiation and
                      settlement. We leverage our negotiation skills and legal
                      expertise to engage with the lienholder on your behalf.
                    </li>
                    <li>
                      <strong>Litigation Support:</strong> In cases where
                      negotiations are unsuccessful, we provide litigation
                      support to challenge the validity of the lien through the
                      judicial system.
                    </li>
                    <li>
                      <strong>Post-Removal Support:</strong> Once the lien has
                      been successfully removed, we provide support and guidance
                      to ensure that your property remains free from
                      encumbrances.
                    </li>
                  </ul>
                  <p>
                    <strong>Get Started with Lien Removal</strong>
                  </p>
                  <p>
                    Don't let a lien hold you back from realizing your financial
                    goals. Contact us today to schedule a consultation and learn
                    how we can help you navigate the lien removal process and
                    secure a brighter future for you and your property.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container my-5">
        <h1 className="my-2 text-center">
          Your DPDP Act 2023 Compliance Partner
        </h1>
        <div className="d-flex align-items-center justify-content-center">
          <div className="line"></div>
        </div>
        <div className="container mt-5">
          <div className="row my-4 mx-1">
            <div className="col-lg-12 card card-body">
              <div className="d-inline-flex gap-1 text-start">
                <a
                  data-bs-toggle="collapse"
                  href="#dpdpActOverview"
                  role="button"
                  aria-expanded="false"
                  aria-controls="dpdpActOverview"
                  className="text-primary">
                  <p>
                    Welcome to Cyber Legal Advisory, your trusted partner for
                    navigating the complexities of the Data Protection and
                    Privacy Directive (DPDP) Act 2023. Our team of experts
                    specializes in providing comprehensive solutions and
                    guidance to ensure your organization meets the requirements
                    set forth by the DPDP Act 2023.
                    <span>
                      <strong className="fw-bold text-black">
                        <i className="fa-solid fa-caret-down mx-2"></i>
                      </strong>
                      Read More
                    </span>
                  </p>
                </a>
              </div>
              <div
                className="collapse smooth-collapse mt-2"
                id="dpdpActOverview">
                <div>
                  <p>
                    <strong>Why Choose Us?</strong>
                  </p>
                  <ul className="padding-l">
                    <li>
                      <strong>In-depth Knowledge:</strong> Our team possesses a
                      deep understanding of the DPDP Act 2023 and its
                      implications for businesses.
                    </li>
                    <li>
                      <strong>Tailored Solutions:</strong> We offer customized
                      solutions designed to address your organization's specific
                      compliance needs.
                    </li>
                    <li>
                      <strong>Proactive Approach:</strong> We work closely with
                      your team to conduct thorough assessments, identify
                      potential risks, and implement robust measures to mitigate
                      those risks.
                    </li>
                    <li>
                      <strong>Comprehensive Services:</strong> From initial
                      compliance assessments to policy development,
                      implementation, and ongoing monitoring, we offer a full
                      suite of services.
                    </li>
                  </ul>
                  <p>
                    <strong>Our DPDP Act 2023 Expertise</strong>
                  </p>
                  <ul className="padding-l">
                    <li>
                      <strong>Compliance Assessments:</strong> We conduct
                      comprehensive assessments to identify gaps and areas of
                      non-compliance with the DPDP Act 2023.
                    </li>
                    <li>
                      <strong>Policy Development:</strong> We assist in
                      developing and implementing robust data protection and
                      privacy policies and procedures.
                    </li>
                    <li>
                      <strong>Training and Education:</strong> We provide
                      training programs and workshops to educate your employees
                      about their roles and responsibilities under the DPDP Act
                      2023.
                    </li>
                    <li>
                      <strong>Data Breach Response:</strong> In the event of a
                      data breach, our experts are available to provide
                      immediate assistance and guidance.
                    </li>
                  </ul>
                  <p>
                    <strong>Get Started with DPDP Act 2023 Compliance</strong>
                  </p>
                  <p>
                    Ready to ensure your organization's compliance with the Data
                    Protection and Privacy Directive Act 2023? Contact us today
                    to schedule a consultation and learn how we can help you
                    navigate the complexities of data protection and privacy
                    regulations and safeguard your organization's sensitive
                    information.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container my-5">
        <h1 className="my-2 text-center">Your Trusted Crypto Exchange</h1>
        <div className="d-flex align-items-center justify-content-center">
          <div className="line"></div>
        </div>
        <div className="container mt-5">
          <div className="row my-4 mx-1">
            <div className="col-lg-12 card card-body">
              <div className="d-inline-flex gap-1 text-start">
                <a
                  data-bs-toggle="collapse"
                  href="#cryptoExchangeOverview"
                  role="button"
                  aria-expanded="false"
                  aria-controls="cryptoExchangeOverview"
                  className="text-primary">
                  <p>
                    At Cyber Legal Advisory, we're dedicated to providing a
                    seamless and secure cryptocurrency exchange platform for
                    individuals and businesses. With our user-friendly
                    interface, robust security measures, and extensive coin
                    selection, we make cryptocurrency trading accessible to
                    everyone.
                    <span>
                      <strong className="fw-bold text-black">
                        <i className="fa-solid fa-caret-down mx-2"></i>
                      </strong>
                      Read More
                    </span>
                  </p>
                </a>
              </div>
              <div
                className="collapse smooth-collapse mt-2"
                id="cryptoExchangeOverview">
                <div w>
                  <p>
                    <strong>Why Choose Us?</strong>
                  </p>
                  <ul className="padding-l">
                    <li>
                      <strong>Security:</strong> We prioritize security and
                      employ industry-leading protocols to protect your funds
                      and personal information.
                    </li>
                    <li>
                      <strong>User Experience:</strong> Our platform is designed
                      to provide a seamless trading experience for both novice
                      and experienced users.
                    </li>
                    <li>
                      <strong>Wide Coin Selection:</strong> We offer a wide
                      selection of cryptocurrencies for trading, including
                      popular coins and emerging tokens.
                    </li>
                    <li>
                      <strong>Liquidity:</strong> We partner with reputable
                      liquidity providers to ensure competitive pricing and
                      ample liquidity for all trades.
                    </li>
                  </ul>
                  <p>
                    <strong>Our Crypto Exchange Services</strong>
                  </p>
                  <ul className="padding-l">
                    <li>
                      <strong>Buy, Sell, and Trade:</strong> Our platform allows
                      you to buy, sell, and trade a variety of cryptocurrencies
                      with ease.
                    </li>
                    <li>
                      <strong>Advanced Trading Tools:</strong> For experienced
                      traders, we offer advanced trading tools and features.
                    </li>
                    <li>
                      <strong>Mobile Accessibility:</strong> Trade on the go
                      with our mobile app.
                    </li>
                    <li>
                      <strong>Customer Support:</strong> Our dedicated customer
                      support team is here to help.
                    </li>
                  </ul>
                  <p>
                    <strong>Get Started with Crypto Trading</strong>
                  </p>
                  <p>
                    Ready to dive into the exciting world of cryptocurrency
                    trading? Sign up for an account today and start trading with
                    confidence. With our secure platform, wide coin selection,
                    and user-friendly interface, we make it easy for you to buy,
                    sell, and trade cryptocurrencies hassle-free.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="container my-5">
        <h1 className="my-2 text-center">Legal Considerations</h1>
        <div className="d-flex align-items-center justify-content-center">
          <div className="line"></div>
        </div>
        <div className="container mt-5">
          <div className="row my-4 mx-1">
            <div className="col-lg-12 card card-body">
              <div className="d-inline-flex gap-1 text-start">
                <a
                  data-bs-toggle="collapse"
                  href="#service1-subservice1"
                  role="button"
                  aria-expanded="false"
                  aria-controls="service1-subservice1">
                  <h4 className="d-flex align-items-center">
                    <i className="fa-solid fa-plus mx-4"></i>
                    Data Protection
                  </h4>
                </a>
              </div>
              <div
                className="collapse smooth-collapse mt-2"
                id="service1-subservice1">
                <div className="p-3">
                  <p>
                    We ensure that your business adheres to the highest
                    standards of data protection and privacy laws, including the
                    Personal Data Protection Bill, 2019 (PDPB) in India. Our
                    services include drafting and reviewing data protection
                    policies, advising on data breaches, and ensuring compliance
                    with global standards like GDPR. We help safeguard your
                    business from legal liabilities associated with data
                    mishandling.
                  </p>
                  <ul className="padding-l">
                    <li>
                      <strong>Compliance:</strong> Ensure adherence to data
                      protection laws such as GDPR, India’s Data Protection
                      Bill, and other relevant regulations.
                    </li>
                    <li>
                      <strong>Policy Development:</strong> Create and implement
                      data protection policies and procedures.
                    </li>
                    <li>
                      <strong>Training:</strong> Conduct training sessions for
                      employees on data protection best practices.
                    </li>
                    <li>
                      <strong>Incident Response:</strong> Provide support in
                      case of data breaches, including investigation and
                      mitigation.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row my-4 mx-1">
            <div className="col-lg-12 card card-body">
              <div className="d-inline-flex gap-1 text-start">
                <a
                  data-bs-toggle="collapse"
                  href="#service1-subservice2"
                  role="button"
                  aria-expanded="false"
                  aria-controls="service6">
                  <h4 className="d-flex align-items-center">
                    <i className="fa-solid fa-plus mx-4"></i>
                    Contract Law
                  </h4>
                </a>
              </div>
              <div
                className="collapse smooth-collapse mt-2"
                id="service1-subservice2">
                <div className="p-3">
                  <p>
                    Our expertise in contract law is vital for businesses in the
                    digital economy. We draft, review, and negotiate various
                    contracts, including software licenses, service agreements,
                    and confidentiality agreements. Our focus is on ensuring
                    that your business interests are protected and that
                    contracts align with regulatory requirements.
                  </p>
                  <ul className="padding-l">
                    <li>
                      <strong>Drafting and Reviewing:</strong> Draft and review
                      contracts to ensure they are legally sound and protect
                      your clients’ interests.
                    </li>
                    <li>
                      <strong>Negotiation:</strong> Assist in negotiating terms
                      and conditions to achieve favourable outcomes.
                    </li>
                    <li>
                      <strong>Dispute Resolution:</strong> Provide legal support
                      in resolving contract disputes through mediation,
                      arbitration, or litigation.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row my-4 mx-1">
            <div className="col-lg-12 card card-body">
              <div className="d-inline-flex gap-1 text-start">
                <a
                  data-bs-toggle="collapse"
                  href="#service1-subservice3"
                  role="button"
                  aria-expanded="false"
                  aria-controls="service2">
                  <h4 className="d-flex align-items-center">
                    <i className="fa-solid fa-plus mx-4"></i>
                    Intellectual Property
                  </h4>
                </a>
              </div>
              <div
                className="collapse smooth-collapse mt-2"
                id="service1-subservice3">
                <div className="p-3">
                  <p>
                    Protecting your digital assets is crucial in the digital
                    age. Our intellectual property services include trademark
                    registration, copyright protection, and patent filing. We
                    also offer strategic advice on IP portfolio management, IP
                    litigation, and enforcement of IP rights to secure your
                    innovations and creative works.
                  </p>
                  <ul className="padding-l">
                    <li>
                      <strong>Registration:</strong> Assist in the registration
                      of patents, trademarks, copyrights, and designs.
                    </li>
                    <li>
                      <strong>Protection:</strong> Develop strategies to protect
                      intellectual property rights and prevent infringement.
                    </li>
                    <li>
                      <strong>Litigation:</strong> Represent clients in
                      intellectual property disputes and litigation.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row my-4 mx-1">
            <div className="col-lg-12 card card-body">
              <div className="d-inline-flex gap-1 text-start">
                <a
                  data-bs-toggle="collapse"
                  href="#service1-subservice4"
                  role="button"
                  aria-expanded="false"
                  aria-controls="service3">
                  <h4 className="d-flex align-items-center">
                    <i className="fa-solid fa-plus mx-4"></i>
                    Financial Regulations
                  </h4>
                </a>
              </div>
              <div
                className="collapse smooth-collapse mt-2"
                id="service1-subservice4">
                <div className="p-3">
                  <p>
                    Navigating the complex financial regulations that govern
                    fintech, crypto, and e-commerce businesses is our forte. We
                    provide guidance on compliance with financial regulations,
                    anti-money laundering (AML) laws, and KYC requirements. Our
                    team ensures that your business operations are in full
                    compliance with the Reserve Bank of India (RBI) guidelines
                    and other financial regulatory bodies.
                  </p>
                  <ul className="padding-l">
                    <li>
                      <strong>Compliance:</strong> Ensure compliance with
                      financial regulations and standards.
                    </li>
                    <li>
                      <strong>Advisory:</strong> Provide advisory services on
                      financial regulatory matters.
                    </li>
                    <li>
                      <strong>Risk Management:</strong> Develop risk management
                      strategies to mitigate financial risks.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row my-4 mx-1">
            <div className="col-lg-12 card card-body">
              <div className="d-inline-flex gap-1 text-start">
                <a
                  data-bs-toggle="collapse"
                  href="#service1-subservice5"
                  role="button"
                  aria-expanded="false"
                  aria-controls="service4">
                  <h4 className="d-flex align-items-center">
                    <i className="fa-solid fa-plus mx-4"></i>
                    Consumer Protection
                  </h4>
                </a>
              </div>
              <div
                className="collapse smooth-collapse mt-2"
                id="service1-subservice5">
                <div className="p-3">
                  <p>
                    We help businesses understand and comply with consumer
                    protection laws, ensuring fair practices and transparency in
                    the digital marketplace. Our services include advising on
                    e-commerce regulations, consumer rights, and dispute
                    resolution, protecting your business from potential legal
                    challenges.
                  </p>
                  <ul className="padding-l">
                    <li>
                      <strong>Compliance:</strong> Ensure adherence to consumer
                      protection laws and regulations.
                    </li>
                    <li>
                      <strong>Advisory:</strong> Provide guidance on consumer
                      rights and obligations.
                    </li>
                    <li>
                      <strong>Dispute Resolution:</strong> Assist in resolving
                      consumer disputes through legal channels.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="row my-4 mx-1">
            <div className="col-lg-12 card card-body">
              <div className="d-inline-flex gap-1 text-start">
                <a
                  data-bs-toggle="collapse"
                  href="#service1-subservice6"
                  role="button"
                  aria-expanded="false"
                  aria-controls="service5">
                  <h4 className="d-flex align-items-center">
                    <i className="fa-solid fa-plus mx-4"></i>
                    Taxation
                  </h4>
                </a>
              </div>
              <div
                className="collapse smooth-collapse mt-2"
                id="service1-subservice6">
                <div className="p-3">
                  <p>
                    Our taxation services are tailored to meet the needs of
                    businesses operating in the digital economy. We provide
                    comprehensive advice on tax compliance, planning, and
                    structuring. Our expertise covers GST, income tax, and
                    international tax matters, helping you optimize your tax
                    liabilities and avoid legal pitfalls.
                  </p>
                  <ul className="padding-l">
                    <li>
                      <strong>Tax Planning:</strong> Develop tax-efficient
                      strategies for businesses.
                    </li>
                    <li>
                      <strong>Compliance:</strong> Ensure compliance with tax
                      laws and regulations.
                    </li>
                    <li>
                      <strong>Representation:</strong> Represent clients in tax
                      disputes and litigation.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="my-5 container ">
        <h1 className="my-2 text-center">Security</h1>
        <div className="d-flex align-items-center justify-content-center">
          <div className="line"></div>
        </div>
        <div className="container mt-5">
          <div className="row my-4 mx-1">
            <div className="col-lg-12 card card-body">
              <div className="d-inline-flex gap-1 text-start">
                <a
                  data-bs-toggle="collapse"
                  href="#service2"
                  role="button"
                  aria-expanded="false"
                  aria-controls="service7">
                  <h4 className="d-flex align-items-center">
                    <i className="fa-solid fa-plus mx-4"></i>
                    Cybersecurity, Digital Forensics, and Data Recovery
                  </h4>
                </a>
              </div>
              <div className="collapse smooth-collapse mt-2" id="service2">
                <div className="p-3">
                  <p>
                    In the event of a cyber-attack, time is of the essence. Our
                    cybersecurity services include risk assessments,
                    vulnerability management, and incident response. We also
                    offer digital forensics to investigate cybercrimes and
                    recover lost data. Our team works swiftly to mitigate
                    damage, identify the source of the breach, and provide
                    evidence for legal proceedings if necessary.
                  </p>
                  <ul className="padding-l">
                    <li>
                      <strong>Cybersecurity Audits:</strong> Conduct
                      comprehensive cybersecurity audits to identify
                      vulnerabilities.
                    </li>
                    <li>
                      <strong>Digital Forensics:</strong> Provide digital
                      forensics services to investigate cyber incidents and
                      gather evidence.
                    </li>
                    <li>
                      <strong>Data Recovery:</strong> Offer data recovery
                      services to retrieve lost or corrupted data.
                    </li>
                    <li>
                      <strong>Incident Response:</strong> Develop and implement
                      incident response plans to handle cybersecurity breaches.
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="my-5 container">
        <h1 className="my-2 text-center">Corporate</h1>
        <div className="d-flex align-items-center justify-content-center">
          <div className="line"></div>
        </div>
        <div className="container mt-5">
          <div className="row my-4 mx-1">
            <div className="col-lg-12 card card-body">
              <div className="d-inline-flex gap-1 text-start">
                <a
                  data-bs-toggle="collapse"
                  href="#service4-subservice1"
                  role="button"
                  aria-expanded="false"
                  aria-controls="service8">
                  <h4 className="d-flex align-items-center">
                    <i className="fa-solid fa-plus mx-4"></i>
                    Competition/ Antitrust
                  </h4>
                </a>
              </div>
              <div
                className="collapse smooth-collapse mt-2"
                id="service4-subservice1">
                <div className="p-3">
                  <p>
                    We provide comprehensive legal advice on competition laws to
                    help your business avoid anti-competitive practices and
                    ensure compliance with the Competition Commission of India
                    (CCI) regulations. Our services include merger control,
                    cartel investigations, and antitrust litigation.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row my-4 mx-1">
            <div className="col-lg-12 card card-body">
              <div className="d-inline-flex gap-1 text-start">
                <a
                  data-bs-toggle="collapse"
                  href="#service4-subservice2"
                  role="button"
                  aria-expanded="false"
                  aria-controls="service9">
                  <h4 className="d-flex align-items-center">
                    <i className="fa-solid fa-plus mx-4"></i>
                    Corporate Governance
                  </h4>
                </a>
              </div>
              <div
                className="collapse smooth-collapse mt-2"
                id="service4-subservice2">
                <div className="p-3">
                  <p>
                    Our corporate governance services are designed to help
                    businesses establish robust governance structures. We advise
                    on board practices, shareholder rights, corporate social
                    responsibility, and compliance with statutory obligations to
                    ensure transparency and accountability within your
                    organization.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row my-4 mx-1">
            <div className="col-lg-12 card card-body">
              <div className="d-inline-flex gap-1 text-start">
                <a
                  data-bs-toggle="collapse"
                  href="#service4-subservice3"
                  role="button"
                  aria-expanded="false"
                  aria-controls="service10">
                  <h4 className="d-flex align-items-center">
                    <i className="fa-solid fa-plus mx-4"></i>
                    Employment
                  </h4>
                </a>
              </div>
              <div
                className="collapse smooth-collapse mt-2"
                id="service4-subservice3">
                <div className="p-3">
                  <p>
                    We assist businesses with all aspects of employment law,
                    including drafting employment contracts, handling disputes,
                    and ensuring compliance with labor laws. Our services also
                    cover workplace policies, employee benefits, and termination
                    procedures.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row my-4 mx-1">
            <div className="col-lg-12 card card-body">
              <div className="d-inline-flex gap-1 text-start">
                <a
                  data-bs-toggle="collapse"
                  href="#service4-subservice4"
                  role="button"
                  aria-expanded="false"
                  aria-controls="service11">
                  <h4 className="d-flex align-items-center">
                    <i className="fa-solid fa-plus mx-4"></i>
                    Intellectual Property
                  </h4>
                </a>
              </div>
              <div
                className="collapse smooth-collapse mt-2"
                id="service4-subservice4">
                <div className="p-3">
                  <p>
                    We offer strategic advice on managing and protecting
                    intellectual property assets, including patents, trademarks,
                    and copyrights. Our services encompass IP audits,
                    registration, enforcement, and litigation to safeguard your
                    innovations and brand identity.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row my-4 mx-1">
            <div className="col-lg-12 card card-body">
              <div className="d-inline-flex gap-1 text-start">
                <a
                  data-bs-toggle="collapse"
                  href="#service4-subservice5"
                  role="button"
                  aria-expanded="false"
                  aria-controls="service12">
                  <h4 className="d-flex align-items-center">
                    <i className="fa-solid fa-plus mx-4"></i>
                    Investment Funds
                  </h4>
                </a>
              </div>
              <div
                className="collapse smooth-collapse mt-2"
                id="service4-subservice5">
                <div className="p-3">
                  <p>
                    Our expertise in investment funds includes advising on the
                    formation, management, and regulation of various types of
                    investment funds. We assist with compliance, structuring,
                    and legal documentation to ensure that your investment
                    operations are legally sound.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row my-4 mx-1">
            <div className="col-lg-12 card card-body">
              <div className="d-inline-flex gap-1 text-start">
                <a
                  data-bs-toggle="collapse"
                  href="#service4-subservice6"
                  role="button"
                  aria-expanded="false"
                  aria-controls="service13">
                  <h4 className="d-flex align-items-center">
                    <i className="fa-solid fa-plus mx-4"></i>
                    Mergers & Acquisitions
                  </h4>
                </a>
              </div>
              <div
                className="collapse smooth-collapse mt-2"
                id="service4-subservice6">
                <div className="p-3">
                  <p>
                    We provide end-to-end legal support for mergers and
                    acquisitions, including due diligence, negotiation, and
                    regulatory compliance. Our goal is to ensure a smooth and
                    successful transaction, whether you are buying, selling, or
                    merging with another company.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row my-4 mx-1">
            <div className="col-lg-12 card card-body">
              <div className="d-inline-flex gap-1 text-start">
                <a
                  data-bs-toggle="collapse"
                  href="#service4-subservice7"
                  role="button"
                  aria-expanded="false"
                  aria-controls="service14">
                  <h4 className="d-flex align-items-center">
                    <i className="fa-solid fa-plus mx-4"></i>
                    Private Client
                  </h4>
                </a>
              </div>
              <div
                className="collapse smooth-collapse mt-2"
                id="service4-subservice7">
                <div className="p-3">
                  <p>
                    Our private client services cater to high-net-worth
                    individuals, providing legal advice on estate planning,
                    wealth management, and succession planning. We help protect
                    and manage your assets, ensuring they are passed on
                    according to your wishes.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row my-4 mx-1">
            <div className="col-lg-12 card card-body">
              <div className="d-inline-flex gap-1 text-start">
                <a
                  data-bs-toggle="collapse"
                  href="#service15"
                  role="button"
                  aria-expanded="false"
                  aria-controls="service15">
                  <h4 className="d-flex align-items-center">
                    <i className="fa-solid fa-plus mx-4"></i>
                    Private Equity
                  </h4>
                </a>
              </div>
              <div className="collapse smooth-collapse mt-2" id="service15">
                <div className="p-3">
                  <p>
                    We offer legal counsel on private equity transactions,
                    including fund formation, investment structuring, and
                    portfolio management. Our services ensure that your private
                    equity investments are compliant with regulatory
                    requirements and optimized for maximum returns.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row my-4 mx-1">
            <div className="col-lg-12 card card-body">
              <div className="d-inline-flex gap-1 text-start">
                <a
                  data-bs-toggle="collapse"
                  href="#service4-subservice8"
                  role="button"
                  aria-expanded="false"
                  aria-controls="service16">
                  <h4 className="d-flex align-items-center">
                    <i className="fa-solid fa-plus mx-4"></i>
                    Real Estate
                  </h4>
                </a>
              </div>
              <div
                className="collapse smooth-collapse mt-2"
                id="service4-subservice8">
                <div className="p-3">
                  <p>
                    Our real estate services cover the entire spectrum of
                    property law, including transactions, leasing, and
                    development. We assist with due diligence, contract
                    negotiation, and regulatory compliance to protect your
                    interests in real estate dealings.
                  </p>
                </div>
              </div>
            </div>
          </div>
          <div className="row my-4 mx-1">
            <div className="col-lg-12 card card-body">
              <div className="d-inline-flex gap-1 text-start">
                <a
                  data-bs-toggle="collapse"
                  href="#service4-subservice9"
                  role="button"
                  aria-expanded="false"
                  aria-controls="service17">
                  <h4 className="d-flex align-items-center">
                    <i className="fa-solid fa-plus mx-4"></i>
                    Taxation
                  </h4>
                </a>
              </div>
              <div
                className="collapse smooth-collapse mt-2"
                id="service4-subservice9">
                <div className="p-3">
                  <p>
                    We provide expert advice on taxation matters, including
                    direct and indirect taxes, international tax planning, and
                    tax litigation. Our services help businesses navigate the
                    complex tax landscape, ensuring compliance and optimizing
                    tax liabilities.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="my-5 container">
        <h1 className="my-2 text-center">Finance</h1>
        <div className="d-flex align-items-center justify-content-center">
          <div className="line"></div>
        </div>
        <div className="row my-4 mx-1">
          <div className="col-lg-12 card card-body">
            <div className="d-inline-flex gap-1 text-start">
              <a
                data-bs-toggle="collapse"
                href="#service6-subservice1"
                role="button"
                aria-expanded="false"
                aria-controls="service1">
                <h4 className="d-flex align-items-center">
                  <i className="fa-solid fa-plus mx-4"></i>
                  Banking and Finance
                </h4>
              </a>
            </div>
            <div
              className="collapse smooth-collapse mt-2"
              id="service6-subservice1">
              <div className="p-3">
                <p>
                  Our banking and finance services include advising on loan
                  agreements, project financing, and regulatory compliance. We
                  assist with structuring and negotiating financial
                  transactions, ensuring they align with legal requirements and
                  business objectives.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row my-4 mx-1">
          <div className="col-lg-12 card card-body">
            <div className="d-inline-flex gap-1 text-start">
              <a
                data-bs-toggle="collapse"
                href="#service6-subservice2"
                role="button"
                aria-expanded="false"
                aria-controls="service2">
                <h4 className="d-flex align-items-center">
                  <i className="fa-solid fa-plus mx-4"></i>
                  Infrastructure & Project Finance
                </h4>
              </a>
            </div>
            <div
              className="collapse smooth-collapse mt-2"
              id="service6-subservice2">
              <div className="p-3">
                <p>
                  We offer legal support for infrastructure and project finance
                  transactions, including drafting and negotiating contracts,
                  securing financing, and ensuring regulatory compliance. Our
                  services help drive successful infrastructure projects, from
                  conception to completion.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row my-4 mx-1">
          <div className="col-lg-12 card card-body">
            <div className="d-inline-flex gap-1 text-start">
              <a
                data-bs-toggle="collapse"
                href="#service6-subservice3"
                role="button"
                aria-expanded="false"
                aria-controls="service3">
                <h4 className="d-flex align-items-center">
                  <i className="fa-solid fa-plus mx-4"></i>
                  Insolvency & Bankruptcy
                </h4>
              </a>
            </div>
            <div
              className="collapse smooth-collapse mt-2"
              id="service6-subservice3">
              <div className="p-3">
                <p>
                  We provide legal advice on insolvency and bankruptcy matters,
                  assisting with restructuring, liquidation, and creditor
                  negotiations. Our goal is to help businesses navigate
                  financial distress and achieve the best possible outcome for
                  all stakeholders.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="my-5 container">
        <h1 className="my-2 text-center">Dispute Resolution</h1>
        <div className="d-flex align-items-center justify-content-center">
          <div className="line"></div>
        </div>
        <div className="row my-4 mx-1">
          <div className="col-lg-12 card card-body">
            <div className="d-inline-flex gap-1 text-start">
              <a
                data-bs-toggle="collapse"
                href="#service7-subservice1"
                role="button"
                aria-expanded="false"
                aria-controls="service1">
                <h4 className="d-flex align-items-center">
                  <i className="fa-solid fa-plus mx-4"></i>
                  Banking and Finance
                </h4>
              </a>
            </div>
            <div
              className="collapse smooth-collapse mt-2"
              id="service7-subservice1">
              <div className="p-3">
                <p>
                  We offer dispute resolution services for banking and
                  finance-related conflicts, including loan disputes, regulatory
                  issues, and fraud investigations. Our expertise in
                  negotiation, mediation, and litigation ensures that your
                  interests are effectively represented.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row my-4 mx-1">
          <div className="col-lg-12 card card-body">
            <div className="d-inline-flex gap-1 text-start">
              <a
                data-bs-toggle="collapse"
                href="#service7-subservice2"
                role="button"
                aria-expanded="false"
                aria-controls="service2">
                <h4 className="d-flex align-items-center">
                  <i className="fa-solid fa-plus mx-4"></i>
                  Infrastructure & Project Finance
                </h4>
              </a>
            </div>
            <div
              className="collapse smooth-collapse mt-2"
              id="service7-subservice2">
              <div className="p-3">
                <p>
                  Our dispute resolution services extend to infrastructure and
                  project finance, where we assist with resolving contract
                  disputes, delays, and other issues that may arise during the
                  course of a project. We aim to protect your investments and
                  maintain project continuity.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row my-4 mx-1">
          <div className="col-lg-12 card card-body">
            <div className="d-inline-flex gap-1 text-start">
              <a
                data-bs-toggle="collapse"
                href="#service7-subservice3"
                role="button"
                aria-expanded="false"
                aria-controls="service3">
                <h4 className="d-flex align-items-center">
                  <i className="fa-solid fa-plus mx-4"></i>
                  Insolvency & Bankruptcy
                </h4>
              </a>
            </div>
            <div
              className="collapse smooth-collapse mt-2"
              id="service7-subservice3">
              <div className="p-3">
                <p>
                  We handle disputes arising from insolvency and bankruptcy
                  proceedings, including creditor disputes, fraudulent
                  transactions, and liquidation challenges. Our services are
                  designed to resolve conflicts efficiently and protect your
                  financial interests.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="my-5 container">
        <h1 className="my-2 text-center">Markets</h1>
        <div className="d-flex align-items-center justify-content-center">
          <div className="line"></div>
        </div>
        <div className="row my-4 mx-1">
          <div className="col-lg-12 card card-body">
            <div className="d-inline-flex gap-1 text-start">
              <a
                data-bs-toggle="collapse"
                href="#service8-subservice1"
                role="button"
                aria-expanded="false"
                aria-controls="service1">
                <h4 className="d-flex align-items-center">
                  <i className="fa-solid fa-plus mx-4"></i>
                  Capital Markets
                </h4>
              </a>
            </div>
            <div
              className="collapse smooth-collapse mt-2"
              id="service8-subservice1">
              <div className="p-3">
                <p>
                  We provide legal advice on capital market transactions,
                  including IPOs, secondary offerings, and securities
                  regulation. Our services ensure compliance with the Securities
                  and Exchange Board of India (SEBI) regulations and help
                  businesses raise capital effectively.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row my-4 mx-1">
          <div className="col-lg-12 card card-body">
            <div className="d-inline-flex gap-1 text-start">
              <a
                data-bs-toggle="collapse"
                href="#service8-subservice2"
                role="button"
                aria-expanded="false"
                aria-controls="service2">
                <h4 className="d-flex align-items-center">
                  <i className="fa-solid fa-plus mx-4"></i>
                  Debt Markets
                </h4>
              </a>
            </div>
            <div
              className="collapse smooth-collapse mt-2"
              id="service8-subservice2">
              <div className="p-3">
                <p>
                  Our expertise in debt markets includes advising on bond
                  issuances, debenture offerings, and other debt instruments. We
                  assist with structuring, negotiation, and compliance to ensure
                  that your debt financing operations are legally sound.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row my-4 mx-1">
          <div className="col-lg-12 card card-body">
            <div className="d-inline-flex gap-1 text-start">
              <a
                data-bs-toggle="collapse"
                href="#service8-subservice3"
                role="button"
                aria-expanded="false"
                aria-controls="service3">
                <h4 className="d-flex align-items-center">
                  <i className="fa-solid fa-plus mx-4"></i>
                  Financial Regulatory
                </h4>
              </a>
            </div>
            <div
              className="collapse smooth-collapse mt-2"
              id="service8-subservice3">
              <div className="p-3">
                <p>
                  We offer comprehensive legal support on financial regulatory
                  matters, helping businesses comply with regulations issued by
                  the RBI, SEBI, and other financial authorities. Our services
                  include advising on licensing, compliance audits, and
                  regulatory reporting.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="my-5 container">
        <h1 className="my-2 text-center">Sectors</h1>
        <div className="d-flex align-items-center justify-content-center">
          <div className="line"></div>
        </div>
        <div className="row my-4 mx-1">
          <div className="col-lg-12 card card-body">
            <div className="d-inline-flex gap-1 text-start">
              <a
                data-bs-toggle="collapse"
                href="#service9-subservice1"
                role="button"
                aria-expanded="false"
                aria-controls="service1">
                <h4 className="d-flex align-items-center">
                  <i className="fa-solid fa-plus mx-4"></i>
                  Fintech
                </h4>
              </a>
            </div>
            <div
              className="collapse smooth-collapse mt-2"
              id="service9-subservice1">
              <div className="p-3">
                <p>
                  Our fintech services include advising on regulatory
                  compliance, product development, and legal risk management. We
                  help fintech companies navigate the complex regulatory
                  landscape, ensuring that their operations are compliant and
                  secure.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row my-4 mx-1">
          <div className="col-lg-12 card card-body">
            <div className="d-inline-flex gap-1 text-start">
              <a
                data-bs-toggle="collapse"
                href="#service9-subservice2"
                role="button"
                aria-expanded="false"
                aria-controls="service2">
                <h4 className="d-flex align-items-center">
                  <i className="fa-solid fa-plus mx-4"></i>
                  Insurance
                </h4>
              </a>
            </div>
            <div
              className="collapse smooth-collapse mt-2"
              id="service9-subservice2">
              <div className="p-3">
                <p>
                  We provide legal advice to the insurance sector, including
                  regulatory compliance, policy drafting, and claims management.
                  Our services help insurance companies and brokers navigate the
                  regulatory environment and manage legal risks effectively.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="row my-4 mx-1">
          <div className="col-lg-12 card card-body">
            <div className="d-inline-flex gap-1 text-start">
              <a
                data-bs-toggle="collapse"
                href="#service9-subservice3"
                role="button"
                aria-expanded="false"
                aria-controls="service3">
                <h4 className="d-flex align-items-center">
                  <i className="fa-solid fa-plus mx-4"></i>
                  Technology, Media, and Telecommunications
                </h4>
              </a>
            </div>
            <div
              className="collapse smooth-collapse mt-2"
              id="service9-subservice3">
              <div className="p-3">
                <p>
                  We offer specialized legal services to the technology, media,
                  and telecommunications sectors, including advising on
                  intellectual property, data privacy, and regulatory
                  compliance. Our goal is to help businesses in these sectors
                  thrive in a rapidly evolving digital landscape.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
