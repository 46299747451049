import React, { useState, useRef } from "react";
import success from "../img/who-we-are-1.jpg";
import business from "../img/who-we-are-2.jpg";
import clients from "../img/happy-clients.png";
import court from "../img/court-order.png";
import rewards from "../img/rewards.png";
import experts from "../img/expert-advisors.png";
import Counter from "./Counter";
import Clients from "./Clients";
import Procedure from "./Procedure";
import { Link } from "react-router-dom";

export default function Homepage() {
  const [activeServiceId, setActiveServiceId] = useState(null);
  const serviceRefs = useRef({});

  const counterData = [
    { imgSrc: clients, targetNumber: "1000", label: "Satisfied Clients" },
    { imgSrc: court, targetNumber: "2600", label: "Cases Completed" },
    { imgSrc: rewards, targetNumber: "80", label: "Rewards" },
    { imgSrc: experts, targetNumber: "100", label: "Expert Advisors" },
  ];

  const toggleService = (serviceId) => {
    if (serviceId !== activeServiceId) {
      serviceRefs.current[serviceId].scrollIntoView({
        behavior: "smooth",
        block: "center",
      });
    }

    setActiveServiceId((prevServiceId) =>
      prevServiceId === serviceId ? null : serviceId
    );
  };

  const services = [
    {
      id: "service1",
      title: "Legal Considerations",
      subServices: [
        { id: "service1-subservice1", title: "Data Protection" },
        { id: "service1-subservice2", title: "Contract Law" },
        { id: "service1-subservice3", title: "Intellectual Property" },
        { id: "service1-subservice4", title: "Financial Regulations" },
        { id: "service1-subservice5", title: "Consumer Protection" },
        { id: "service1-subservice6", title: "Taxation" },
      ],
    },
    {
      id: "service2",
      title: "Security",
      subServices: [
        { id: "service2-subservice1", title: "Cyber Security" },
        { id: "service2-subservice2", title: "Digital Forensics" },
        { id: "service2-subservice3", title: "Data Recovery" },
      ],
    },
    {
      id: "service4",
      title: "Corporate",
      subServices: [
        { id: "service4-subservice1", title: "Competition/Antitrust" },
        { id: "service4-subservice2", title: "Corporate Governance" },
        { id: "service4-subservice3", title: "Employment" },
        { id: "service4-subservice4", title: "Intellectual Property" },
        { id: "service4-subservice5", title: "Investment Funds" },
        { id: "service4-subservice6", title: "Mergers & Acquisitions" },
        { id: "service4-subservice7", title: "Private Client" },
        { id: "service4-subservice8", title: "Real Estate" },
        { id: "service4-subservice9", title: "Taxation" },
      ],
    },
    {
      id: "service6",
      title: "Finance",
      subServices: [
        { id: "service6-subservice1", title: "Banking and Finance" },
        {
          id: "service6-subservice2",
          title: "Infrastructure & Project Finance",
        },
        { id: "service6-subservice3", title: "Insolvency & Bankruptcy" },
      ],
    },
    {
      id: "service7",
      title: "Dispute Resolution",
      subServices: [
        { id: "service7-subservice1", title: "Banking and Finance" },
        {
          id: "service7-subservice2",
          title: "Infrastructure & Project Finance",
        },
        { id: "service7-subservice3", title: "Insolvency & Bankruptcy" },
      ],
    },
    {
      id: "service8",
      title: "Markets",
      subServices: [
        { id: "service8-subservice1", title: "Capital Markets" },
        { id: "service8-subservice2", title: "Debt Markets" },
        { id: "service8-subservice3", title: "Financial Regulatory" },
      ],
    },
    {
      id: "service9",
      title: "Sectors",
      subServices: [
        { id: "service9-subservice1", title: "Fintech" },
        { id: "service9-subservice2", title: "Insurance" },
        {
          id: "service9-subservice3",
          title: "Technology, Media, and Telecommunications",
        },
      ],
    },
  ];

  return (
    <div className="">
      <div className="front-section">
        <div className="overlay"></div>
        <div className="text-white w-100 d-flex flex-column justify-content-center align-items-center h-100">
          <h4 className="text-center px-2">
            Deciphering Evidence Defining Justice
          </h4>
          <div className="line"></div>
          <p className="fs-3r text-center px-2">
            <b>Cyber Legal Advisory</b>
          </p>
          <div className="d-flex mx-2">
            <button className="btn-size mx-2  px-4 py-3 bg-gold border-none">
              <Link to="/services" className="text-white">
                Services
              </Link>
            </button>
            <button className="btn-size mx-2  px-2 py-3 bg-transparent border-white">
              <Link to="/about" className="text-white">
                About us
              </Link>
            </button>
          </div>
        </div>
      </div>
      <div className="who-we-are-section my-5 text-center">
        <h1 className="my-2">Who We Are</h1>
        <div className="d-flex align-items-center justify-content-center">
          <div className="line"></div>
        </div>

        <div className="who-we-are-section-content">
          <div className="container">
            <div className="row my-4 ">
              <div className="col-lg-6 my-2" style={{ padding: "2rem" }}>
                <div className="who-we-are-card card">
                  <img src={success} className="card-img-top" alt="sucess" />
                  <div className="card-body p-4">
                    <h4 className="card-title mb-3 fw-bold">
                      Your Success, Our Passion
                    </h4>
                    <p className="card- my-2">
                      What sets us apart from traditional law firms is our
                      belief in building strong relationships with our clients.
                      We see ourselves as partners in your success rather than
                      just external advisors. Our friendly approach ensures that
                      you feel comfortable reaching out to us with questions or
                      concerns.
                    </p>
                  </div>
                </div>
              </div>
              <div className="col-lg-6 my-2" style={{ padding: "2rem" }}>
                <div className="who-we-are-card card">
                  <img src={business} className="card-img-top" alt="Business" />
                  <div className="card-body p-4">
                    <h4 className="card-title mb-3 fw-bold">
                      Your Business, Our Law
                    </h4>
                    <p className="card- my-2">
                      In our personal experiences, we have witnessed how strong
                      legal support can make a difference in a business's
                      journey. Many of our clients have shared stories of how
                      our guidance has helped them avoid pitfalls and achieve
                      their goals.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="mission-section my-5 text-center">
        <div className="overlay"></div>
        <div className="container my-4 py-4">
          <div className="row">
            <div className="col-lg-6  my-2">
              <div className="who-we-are-card card">
                <div className="card-body p-4">
                  <h4 className="card-title mb-3 fw-bold">Mission</h4>
                  <p className="card- my-2">
                    Our mission is to be a steadfast legal partner, providing
                    accessible, expert, and compassionate legal counsel. We are
                    committed to empowering individuals and businesses by
                    navigating complex legal landscapes, protecting their
                    rights, and achieving optimal outcomes. Through unwavering
                    integrity and exceptional client service, we strive to build
                    lasting relationships rooted in trust and mutual success.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-6 my-2">
              <div className="who-we-are-card card">
                <div className="card-body p-4">
                  <h4 className="card-title mb-3 fw-bold">Vision</h4>
                  <p className="card- my-2">
                    We aspire to be the preeminent legal consultancy, renowned
                    for our innovative approach, deep legal expertise, and
                    unwavering dedication to client satisfaction. By leveraging
                    technology and human connection, we will redefine the legal
                    experience, making it efficient, transparent, and empowering
                    for all. Our vision is to create a world where individuals
                    and businesses have access to justice and can thrive without
                    fear of legal complexities.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="clients-section text-center my-5">
        <Procedure />
      </div>
      <div className="numbers-section my-5 text-center">
        <div className="overlay"></div>
        <div className="container my-4 py-4">
          <div className="row">
            {counterData.map((item, index) => (
              <div className="col-lg-3 my-4" key={index}>
                <img
                  src={item.imgSrc}
                  className="numbers-sections-img mb-3"
                  alt={item.label}
                />
                <Counter targetNumber={item.targetNumber} duration={2000} />
                <h6>{item.label}</h6>
              </div>
            ))}
          </div>
        </div>
      </div>
      <div className="services-section text-center my-5">
        <h6 className="color-gold">What we offer</h6>
        <h1 className="my-2">Our Services</h1>
        <div className="d-flex align-items-center justify-content-center">
          <div className="line"></div>
        </div>
        <div className="container mt-5">
          <div className="row my-4 mx-1">
            <div className="col-lg-12 card card-body">
              <div className="d-inline-flex gap-1 text-start align-items-center ">
                <Link to={`/services?service=fintechOverview`}>
                  <h4 className="d-flex align-items-center">
                    <i
                      className="fa-solid fa-plus mx-4"
                      style={{ color: "#a3882b" }}></i>
                    Your Fintech Partner
                  </h4>
                </Link>
              </div>
            </div>
          </div>
          <div className="row my-4 mx-1">
            <div className="col-lg-12 card card-body">
              <div className="d-inline-flex gap-1 text-start align-items-center ">
                <Link to={`/services?service=complaintManagementOverview`}>
                  <h4 className="d-flex align-items-center">
                    {" "}
                    <i
                      className="fa-solid fa-plus mx-4"
                      style={{ color: "#a3882b" }}></i>
                    Your Partner for Effective Complaint Management
                  </h4>
                </Link>
              </div>
            </div>
          </div>
          <div className="row my-4 mx-1">
            <div className="col-lg-12 card card-body">
              <div className="d-inline-flex gap-1 text-start align-items-center ">
                <Link to={`/services?service=bankAccountOverview`}>
                  <h4 className="d-flex align-items-center">
                    {" "}
                    <i
                      className="fa-solid fa-plus mx-4"
                      style={{ color: "#a3882b" }}></i>
                    Unfreezing Your Bank Account
                  </h4>
                </Link>
              </div>
            </div>
          </div>
          <div className="row my-4 mx-1">
            <div className="col-lg-12 card card-body">
              <div className="d-inline-flex gap-1 text-start align-items-center ">
                <Link to={`/services?service=transactionMonitoringOverview`}>
                  <h4 className="d-flex align-items-center">
                    {" "}
                    <i
                      className="fa-solid fa-plus mx-4"
                      style={{ color: "#a3882b" }}></i>
                    Your Transaction Monitoring Partner
                  </h4>
                </Link>
              </div>
            </div>
          </div>
          <div className="row my-4 mx-1">
            <div className="col-lg-12 card card-body">
              <div className="d-inline-flex gap-1 text-start align-items-center ">
                <Link to={`/services?service=lienRemovalOverview`}>
                  <h4 className="d-flex align-items-center">
                    {" "}
                    <i
                      className="fa-solid fa-plus mx-4"
                      style={{ color: "#a3882b" }}></i>
                    Your Lien Removal Expert
                  </h4>
                </Link>
              </div>
            </div>
          </div>
          <div className="row my-4 mx-1">
            <div className="col-lg-12 card card-body">
              <div className="d-inline-flex gap-1 text-start align-items-center ">
                <Link to={`/services?service=dpdpActOverview`}>
                  <h4 className="d-flex align-items-center">
                    {" "}
                    <i
                      className="fa-solid fa-plus mx-4"
                      style={{ color: "#a3882b" }}></i>
                    Your DPDP Act 2023 Compliance Partner
                  </h4>
                </Link>
              </div>
            </div>
          </div>
          <div className="row my-4 mx-1">
            <div className="col-lg-12 card card-body">
              <div className="d-inline-flex gap-1 text-start align-items-center ">
                <Link to={`/services?service=cryptoExchangeOverview`}>
                  <h4 className="d-flex align-items-center">
                    {" "}
                    <i
                      className="fa-solid fa-plus mx-4"
                      style={{ color: "#a3882b" }}></i>
                    Your Trusted Crypto Exchange
                  </h4>
                </Link>
              </div>
            </div>
          </div>
          {services.map((service) => (
            <div
              className="row my-4 mx-1"
              key={service.id}
              ref={(el) => (serviceRefs.current[service.id] = el)}>
              <div className="col-lg-12 card card-body">
                <div className="d-inline-flex gap-1 text-start  ">
                  <a
                    data-bs-toggle="collapse"
                    href={`#${service.id}`}
                    role="button"
                    aria-expanded="false"
                    aria-controls={service.id}
                    onClick={() => toggleService(service.id)}>
                    <h4 className="d-flex align-items-center">
                      {" "}
                      <i
                        className="fa-solid fa-plus mx-4"
                        style={{ color: "#a3882b" }}></i>
                      {service.title}
                    </h4>
                  </a>
                </div>
                <div
                  className={`collapse ${
                    activeServiceId === service.id ? "show" : ""
                  } smooth-collapse mt-2`}
                  id={service.id}>
                  {service.subServices.map((subService) => (
                    <Link
                      to={`/services?service=${service.id}&subservice=${subService.id}`}
                      key={subService.id}
                      className="mt-2 sub-services-section">
                      <h5>{subService.title}</h5>
                      <p>{subService.content}</p>
                    </Link>
                  ))}
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <div className="clients-section text-center my-5">
        <h1 className="my-2">Our Clients</h1>
        <div className="d-flex align-items-center justify-content-center">
          <div className="line"></div>
        </div>
        <Clients />
      </div>
    </div>
  );
}
