import React, { useEffect, useState } from "react";

export default function Counter({ targetNumber, duration }) {
  const [count, setCount] = useState(0);

  useEffect(() => {
    let start = 1;
    const end = targetNumber;
    const increment = end / (duration / 10);

    const timer = setInterval(() => {
      start += increment;
      if (start >= end) {
        setCount(end);
        clearInterval(timer);
      } else {
        setCount(Math.ceil(start));
      }
    }, 10);

    return () => clearInterval(timer);
  }, [targetNumber, duration]);

  return <h1>{count}+</h1>;
}
