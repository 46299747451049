import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { caseStudies } from "../data/CaseStudiesData";

export default function CaseStudyDetail() {
  const { id } = useParams();
  const navigate = useNavigate();
  const caseStudy = caseStudies.find((cs) => cs.id === parseInt(id));

  if (!caseStudy) {
    return (
      <div className="container my-5">
        <div className="row">
          <div className="col-lg-12">
            <div className="alert alert-danger" role="alert">
              Case study not found.
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div className="case-study-main-section">
        <div className="overlay"></div>
        <div className="text-white w-100 d-flex flex-column justify-content-center align-items-center h-100">
          <h1>Case Studies</h1>
        </div>
      </div>
      <div className="container my-5">
        <div className="row">
          <div className="col-lg-12">
            <div className="who-we-are-card card container">
              <div className="card-body p-4">
                <h1 className="card-title mb-3 text-center padding-all">
                  {caseStudy.case_title}
                </h1>
                <p className="card-text">{caseStudy.case_content}</p>
                <div
                  className="text-center d-flex justify-content-center align-items-center mt-4 cursor-pointer"
                  onClick={() => navigate("/case-study")}>
                  <i className="fa-solid fa-arrow-left mx-2 fw-bold color-gold"></i>
                  <h4>Go back</h4>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
