import React from "react";
import logo from "../img/logo.png";

export default function Footer() {
  return (
    <div className="bg-blue p-3">
      <div className="container">
        <div className="row">
          <div className="col-lg-4  py-3">
            <img
              src={logo}
              alt="logo"
              style={{
                height: "60px",
                filter: "contrast(110%) brightness(105%)",
              }}
            />{" "}
            <span className="fw-bolder fs-5">Cyber Legal Advisory</span>
            <h6 className="my-2 text-center">Defending you in Cyber World</h6>
          </div>
          <div className="col-lg-2"></div>
          <div className="col-lg-2"></div>
          <div className="col-lg-4 py-3">
            <h4 className="mb-4">Contact</h4>
            <h6>
              <i
                className="fa-solid fa-location-dot me-2"
                style={{ color: "#a3882b" }}></i>
              Office No. 425 Wing B, 4th Floor, Suratwala Mark, Plazzo, Survey
              Number: 27/1, Hinjewadi- Wakad Road, Wakad, Pune 411057.
            </h6>
            <h6>
              <i
                className="fa-solid fa-phone me-2"
                style={{ color: "#a3882b" }}></i>
              8827308869
            </h6>
            <h6>
              <a
                href="mailto:contact@cyberlegaladvisory.com"
                style={{ textDecoration: "none", color: "inherit" }}>
                <i
                  className="fa-solid fa-envelope me-2"
                  style={{ color: "#a3882b" }}></i>
                contact@cyberlegaladvisory.com
              </a>
            </h6>
            <h3 className="mt-2">
              <a
                href="https://www.linkedin.com/company/cyber-legal-advisory1/"
                target="_blank"
                rel="noopener noreferrer">
                <i
                  className="fa-brands fa-linkedin-in me-3"
                  style={{ color: "#a3882b" }}></i>
              </a>
              <a
                href="https://www.instagram.com/cyber_legal_advisory/"
                target="_blank"
                rel="noopener noreferrer">
                <i
                  className="fa-brands fa-instagram me-3"
                  style={{ color: "#a3882b" }}></i>
              </a>
              <a
                href="https://x.com/clegal_advisory"
                target="_blank"
                rel="noopener noreferrer">
                <i
                  className="fa-brands fa-twitter me-3"
                  style={{ color: "#a3882b" }}></i>
              </a>
              <a
                href="https://api.whatsapp.com/send/?phone=918827308869&text&type=phone_number&app_absent=0"
                target="_blank"
                rel="noopener noreferrer">
                <i
                  className="fa-brands fa-whatsapp me-3"
                  style={{ color: "#a3882b" }}></i>
              </a>
              <a
                href="https://www.youtube.com/@CyberLegalAdvisory"
                target="_blank"
                rel="noopener noreferrer">
                <i
                  className="fa-brands fa-youtube me-3"
                  style={{ color: "#a3882b" }}></i>
              </a>
            </h3>
          </div>
        </div>
        <div className="row my-2">
          <div className="line" style={{ width: "100%", height: "1px" }}></div>
        </div>
        <div className="row text-center">
          <div className="col-lg-12 mt-2 color-lighter">
            Copyright &#64; 2024 Cyber Legal Advisory | Powered by Cyber Legal
            Advisory
          </div>
        </div>
      </div>
    </div>
  );
}
