import React from "react";
import { Link, useLocation } from "react-router-dom";
import logo from "../img/logo.png";

const Navbar = ({ className, activeItem, setActiveItem }) => {
  const location = useLocation();

  const getActiveClass = (path) => {
    return location.pathname === path ? "active" : "";
  };

  return (
    <nav className={`navbar navbar-expand-lg fw-bold ${className}`}>
      <div className="container">
        <Link className="navbar-brand" to="/">
          <img
            src={logo}
            alt="logo"
            style={{
              height: "80px",
              filter: "contrast(110%) brightness(105%)",
            }}
          />
        </Link>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarSupportedContent"
          aria-controls="navbarSupportedContent"
          aria-expanded="false"
          aria-label="Toggle navigation">
          <span className="navbar-toggler-icon"></span>
        </button>
        <div
          className="collapse navbar-collapse align-right"
          id="navbarSupportedContent">
          <ul className="navbar-items mb-2 mb-lg-0">
            <li className="nav-item mx-3">
              <Link className={`nav-link ${getActiveClass("/")}`} to="/">
                Home
              </Link>
            </li>
            <li className="nav-item mx-3">
              <Link
                className={`nav-link ${getActiveClass("/about")}`}
                to="/about">
                About
              </Link>
            </li>
            <li className="nav-item mx-3">
              <Link
                className={`nav-link ${getActiveClass("/services")}`}
                to="/services">
                Services
              </Link>
            </li>
            <li className="nav-item mx-3">
              <Link
                className={`nav-link ${getActiveClass("/case-study")}`}
                to="/case-study">
                Case Study
              </Link>
            </li>
            {/* <li className="nav-item mx-3">
              <Link
                className={`nav-link ${getActiveClass("/tech-news")}`}
                to="/tech-news"
              >
                Tech News
              </Link>
            </li> */}
            <li className="nav-item mx-3">
              <Link
                className={`nav-link ${getActiveClass("/contact")}`}
                to="/contact">
                Contact
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
