import React from "react";

export default function Contact() {
  return (
    <div>
      <div className="contact-main-section">
        <div className="overlay"></div>
        <div className="text-white w-100 d-flex flex-column justify-content-center align-items-center h-100">
          <h1>Contact Us</h1>
        </div>
      </div>

      <div className="contact-card-section d-flex justify-content-center align-items-center">
        <div className="container my-5 d-flex justify-content-center">
          <div
            className="card p-4 shadow-lg padding-all"
            style={{ maxWidth: "600px" }}>
            <div className="card-body text-center padding-all">
              <h2 className="mb-4">Get in touch</h2>
              <div className="d-flex align-items-center justify-content-center">
                <div className="line mb-2"></div>
              </div>

              <h6 className="text-start mt-4">
                <i
                  className="fa-solid fa-location-dot me-2"
                  style={{ color: "#a3882b" }}></i>
                Office No. 425 Wing B, 4th Floor, Suratwala Mark, Plazzo, Survey
                Number: 27/1, Hinjewadi- Wakad Road, Wakad, Pune 411057.
              </h6>

              <h6 className="text-start ">
                <a
                  href="mailto:contact@cyberlegaladvisory.com"
                  style={{ textDecoration: "none", color: "inherit" }}>
                  <i
                    className="fa-solid fa-envelope me-2"
                    style={{ color: "#a3882b" }}></i>
                  contact@cyberlegaladvisory.com
                </a>
              </h6>

              <h6 className="text-start">
                <i
                  className="fa-solid fa-phone me-2"
                  style={{ color: "#a3882b" }}></i>
                8827308869
              </h6>

              <div className="mt-4">
                <a
                  href="https://www.linkedin.com/company/cyber-legal-advisory1/"
                  target="_blank"
                  rel="noopener noreferrer">
                  <i
                    className="fa-brands fa-linkedin-in me-3"
                    style={{ color: "#a3882b", fontSize: "1.5rem" }}></i>
                </a>

                <a
                  href="https://www.instagram.com/cyber_legal_advisory/"
                  target="_blank"
                  rel="noopener noreferrer">
                  <i
                    className="fa-brands fa-instagram me-3"
                    style={{ color: "#a3882b", fontSize: "1.5rem" }}></i>
                </a>
                <a
                  href="https://x.com/clegal_advisory"
                  target="_blank"
                  rel="noopener noreferrer">
                  <i
                    className="fa-brands fa-twitter me-3"
                    style={{ color: "#a3882b", fontSize: "1.5rem" }}></i>
                </a>
                <a
                  href="https://www.youtube.com/@CyberLegalAdvisory"
                  target="_blank"
                  rel="noopener noreferrer">
                  <i
                    className="fa-brands fa-youtube me-3"
                    style={{ color: "#a3882b", fontSize: "1.5rem" }}></i>
                </a>
                <a
                  href="https://api.whatsapp.com/send/?phone=918827308869&text&type=phone_number&app_absent=0"
                  target="_blank"
                  rel="noopener noreferrer">
                  <i
                    className="fa-brands fa-whatsapp me-3"
                    style={{ color: "#a3882b", fontSize: "1.5rem" }}></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

// export default function Contact() {
//   return (
//     <div>
//       <div className="contact-main-section">
//         <div className="overlay"></div>
//         <div className="text-white w-100 d-flex flex-column justify-content-center align-items-center h-100">
//           <h1>Contact Us</h1>
//         </div>
//       </div>
//       <div className="contact-text">
//         <div className="container my-5">
//             <div className="row">
//                 <div className="col-lg-12 p-5">
//                     <h2 className="mb-4">Get in touch</h2>
//             <h6>
//               <i
//                 className="fa-solid fa-location-dot me-2"
//                 style={{ color: "#a3882b" }}></i>
//               Office No. 425 Wing B, 4th Floor, Suratwala Mark, Plazzo, Survey
//               Number: 27/1, Hinjewadi- Wakad Road, Wakad, Pune 411057.
//             </h6>
//             <h6>
//               <a
//                 href="mailto:contact@cyberlegaladvisory.com"
//                 style={{ textDecoration: "none", color: "inherit" }}>
//                 <i
//                   className="fa-solid fa-envelope me-2"
//                   style={{ color: "#a3882b" }}></i>
//                 contact@cyberlegaladvisory.com
//               </a>
//             </h6>
//             <h6>
//               <i
//                 className="fa-solid fa-phone me-2"
//                 style={{ color: "#a3882b" }}></i>
//               8827308869
//             </h6>

//             <h3 className="mt-2">
//               <a
//                 href="https://www.linkedin.com/in/your-profile"
//                 target="_blank"
//                 rel="noopener noreferrer">
//                 <i
//                   className="fa-brands fa-linkedin-in me-3"
//                   style={{ color: "#a3882b" }}></i>
//               </a>
//               <a
//                 href="https://twitter.com/your-profile"
//                 target="_blank"
//                 rel="noopener noreferrer">
//                 <i
//                   className="fa-brands fa-twitter me-3"
//                   style={{ color: "#a3882b" }}></i>
//               </a>
//               <a
//                 href="https://www.facebook.com/your-profile"
//                 target="_blank"
//                 rel="noopener noreferrer">
//                 <i
//                   className="fa-brands fa-facebook me-3"
//                   style={{ color: "#a3882b" }}></i>
//               </a>
//             </h3>
//                 </div>
//                 {/* <div className="col-lg-6 p-5 text-center">
//                     <h3>Message us</h3>
//                     <h6 className="color-gold">Contact Form</h6>
//                     <ContactForm />
//                 </div> */}
//             </div>
//         </div>
//       </div>
//     </div>
//   );
// }
