import React from "react";

export default function Procedure() {
  return (
    <div className="who-we-are-section my-5 text-center">
      <h1 className="my-2">4 Step Procedure</h1>
      <div className="d-flex align-items-center justify-content-center">
        <div className="line"></div>
      </div>

      <div className="who-we-are-section-content">
        <div className="container">
          <div className="row my-4 ">
            <div className="col-lg-3 my-2 ">
              <div
                className="who-we-are-card card py-4"
                style={{ alignItems: "center" }}>
                <div className="circle">
                  <i className="fa-solid fa-1">.</i>
                </div>

                <div className="card-body">
                  <h4 className="card-title mb-3 fw-bold">
                    Case Intake and Assessment
                  </h4>
                  <p className="card- my-2">
                    Thoroughly understanding the client's situation is
                    paramount. This involves active listening, gathering
                    essential details, and identifying core legal issues. A
                    comprehensive grasp of the facts forms the foundation of a
                    robust case.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 my-2">
              <div
                className="who-we-are-card card py-4"
                style={{ alignItems: "center" }}>
                <div className="circle">
                  <i className="fa-solid fa-2"></i>
                </div>
                <div className="card-body">
                  <h4 className="card-title mb-3 fw-bold">
                    Legal Research and Analysis{" "}
                  </h4>
                  <p className="card- my-2">
                    In-depth legal research is conducted to build a strong case.
                    Relevant laws, precedents, and case history are examined to
                    identify potential legal strategies and anticipate
                    challenges. This phase establishes a solid legal framework.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 my-2">
              <div
                className="who-we-are-card card py-4"
                style={{ alignItems: "center" }}>
                <div className="circle">
                  <i className="fa-solid fa-3"></i>
                </div>
                <div className="card-body">
                  <h4 className="card-title mb-3 fw-bold">
                    Strategy Development{" "}
                  </h4>
                  <p className="card- my-2">
                    Based on the gathered information, a tailored legal strategy
                    is crafted. This involves determining the most effective
                    approach, whether negotiation, mediation, or litigation. The
                    strategy aligns with the client's objectives and the case's
                    unique circumstances.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-lg-3 my-2">
              <div
                className="who-we-are-card card py-4"
                style={{ alignItems: "center" }}>
                <div className="circle">
                  <i className="fa-solid fa-4"></i>
                </div>
                <div className="card-body">
                  <h4 className="card-title mb-3 fw-bold">Case Resolution </h4>
                  <p className="card- my-2">
                    The final phase focuses on achieving the desired outcome.
                    Through effective advocacy, negotiation, and litigation (if
                    necessary), the lawyer strives to protect the client's
                    interests and secure a favourable resolution.The final phase
                    focuses on achieving the desired outcome. Through effective
                    advocacy, negotiation, and litigation (if necessary), the
                    lawyer strives to protect the client's interests and secure
                    a favourable resolution.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
